import React from 'react';
import Navbar from '../../components/common/navbar';
import BGHero from '../../assets/images/bg-nb-hero.png';
export default function Hero() {
  return (
    <div
      className='bg-dark h-full xl:h-screen pb-5 xl:pb-0 bg-100-0 bg-no-repeat bg-cover bg-origin-content sm:hero-bg-none'
      style={{ backgroundImage: `url(${BGHero})` }}
    >
      <Navbar />
      <div className='container'>
        <div className='px-5 md:px-10'>
          <div className='w-full lg:w-3/6 xl:w-6/12 pt-8 xl:pt-32 block'>
            <div className='block'>
              <h1 className='font-playfair font-bold text-4xl xl:text-6xl text-white max-w-2xl xl:leading-extra-loose'>
                Expert freelancers in the category you want
              </h1>
              <p className='text-white font-regular text-base mt-7 max-w-lg'>
                Turn your vision into a reality by hiring top-tier freelancers
                who can get your work done with high quality guaranteed.
              </p>
            </div>
            <div className='block'>
              <form className='mt-5 flex flex-col xl:flex-row'>
                <div className=' rounded-t-lg xl:rounded-tr-none xl:rounded-l-lg px-10 py-4 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white border-none focus:outline-none'>
                  <label className='block text-xs font-bold mb-1 uppercase'>
                    Category
                  </label>
                  <input
                    className='border-none focus:outline-none'
                    placeholder='Try “Software Engineering”'
                  />
                </div>
                <div className='flex flex-row'>
                  <div className='w-full rounded-bl-lg xl:rounded-bl-none border-l px-10 py-4 border-t mr-0 text-gray-800 border-gray-200 bg-white focus:outline-none'>
                    <label className='block text-xs font-bold mb-1 uppercase'>
                      Location
                    </label>
                    <input
                      className='border-none focus:outline-none'
                      placeholder='Try “Saudi Arabia”'
                    />
                  </div>
                  <button
                    className='px-5 rounded-tr-none rounded-br-lg xl:rounded-r-lg bg-tuftsBlue text-white font-bold p-4 uppercase border-none focus:outline-none'
                    type='button'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-10 w-10 m-auto'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                      />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
            <div className='mt-2 max-w-2xl'>
              <div className='space-y-3'>
                <button className='inline-block py-1.5 px-2 mr-3 bg-chip rounded-full font-regular text-sm focus:outline-none'>
                  Product design
                </button>
                <button className='inline-block py-1.5 px-2 mr-3 bg-chip rounded-full font-regular text-sm focus:outline-none'>
                  Search optimization
                </button>
                <button className='inline-block py-1.5 px-2 mr-3 bg-chip rounded-full font-regular text-sm focus:outline-none'>
                  Digital marketing
                </button>
                <button className='inline-block py-1.5 px-2 mr-3 bg-chip rounded-full font-regular text-sm focus:outline-none'>
                  Front end development
                </button>
                <button className='inline-block py-1.5 px-2 mr-3 bg-chip rounded-full font-regular text-sm focus:outline-none'>
                  UX design
                </button>
                <button className='inline-block py-1.5 px-2 mr-3 bg-chip rounded-full font-regular text-sm focus:outline-none'>
                  Ecommerce development
                </button>
                <button className='inline-block py-1.5 px-2 mr-3 bg-chip rounded-full font-regular text-sm focus:outline-none'>
                  Low-code/No-code
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
