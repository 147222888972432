/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'gatsby';
import ProfileEllipse from '../../assets/images/profile-ellipse.png';

export default function BlogPost({ item }) {
  return (
    <div className='flex flex-start flex-col min-w-72 xl:flex-row mr-4 xl:mr-0'>
      <div>
        <Link to='/'>
          <img
            src={item}
            alt='Blog Post'
            className='w-auto xl:w-80 h-auto xl:min-h-52 rounded'
          />
        </Link>
      </div>
      <div className='flex-1'>
        <div className='ml-2 xl:ml-4 mt-2 xl:mt-0'>
          <span className='text-sm text-teal-500 block mb-1 font-bold uppercase'>
            ON FREELANCING
          </span>
          <Link to='/'>
            <h2 className='text-base text-title font-bold mb-2'>
              Dropbox, Deloitte: Latest Companies to Roll Out Permanent Work
              From Home Option to Employees
            </h2>
          </Link>
          <div className='flex items-center my-2'>
            <img
              src={ProfileEllipse}
              alt='Profile Avatar'
              className='h-8 w-8 rounded-full'
            />
            <p className='text-sm text-primary font-bold ml-2'>
              Carolyne Njeri · <span>October 28, 2020</span>
            </p>
          </div>
          <p className='text-xs text-primary'>
            <span className='line-clamp-4'>
              <p>
                Ac pulvinar lacus urna euismod vitae sed fusce. Ultrices hac leo
                vitae, ut. Ac id placerat pretium, faucibus urna nullam duis.
                Convallis fames sit quam augue. Facilisi nunc lorem lectus
                mauris. Quam auctor neque velit, netus purus ultrices. In at
                dignissim elementum penatibus viverra sed. Conse...
              </p>
            </span>
            <Link className='text-tuftsBlue font-medium block mt-px' to='/'>
              Read more
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
