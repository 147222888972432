/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'gatsby';
import FreelancerCard from '../../components/homePage/freelancerCard';
// import Profile1 from '../../assets/images/freelancer1.png';
// import Profile2 from '../../assets/images/freelancer2.png';
// import Profile3 from '../../assets/images/freelancer3.png';
// import Profile4 from '../../assets/images/freelancer4.png';

export default function FreelancersSection({freelancers}) {
  return (
    <div className='fs-section py-20 px-10 lg:p-20'>
      <div className='container'>
        <div className='flex flex-col lg:flex-row justify-between'>
          <section>
            <h1 className='font-playfair text-3xl font-bold text-title'>
              Explore top-rated freelancers in all categories
            </h1>
            <p className='mt-5 lg:mt-3 sm:flex font-roboto text-base font-regular text-primary'>
              Other clients like you found those freelancers to be top rated for
              their work
            </p>
          </section>
          <section className='explore-more flex items-center'>
            <div className='mt-5 lg:mt-0'>
              <Link
                to='/'
                className='w-full flex items-center justify-center border border-transparent text-base font-roboto font-bold text-tuftsBlue'
              >
                Explore more freelancers
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5 ml-4'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  />
                </svg>
              </Link>
            </div>
          </section>
        </div>
        <div className='mt-16'>
          <div className='grid xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 grid-flow-rows gap-12'>
            {freelancers.map((item, index) => (
              <FreelancerCard item={item} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
