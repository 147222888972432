/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import SkillVerifiedIcon from '../../assets/images/skill-verified.png';
import InstantChatIcon from '../../assets/images/instant.png';
import NewTalentIcon from '../../assets/images/new-talent.png';
import VerifiedProIcon from '../../assets/images/verified-pro-lg.png';
import RatingIcon from '../../assets/images/feedback.png';
import StatusOnline from '../../assets/images/ellipse-online.png';

const FreelancerCard = ({ item }) => {
  return (
    <div className='col-span-1'>
      <Link to='/'>
        <div className='shadow rounded-lg bg-white border'>
          <div className='relative'>
            <img
              src={item?.photoUrl}
              className='w-full h-56 object-cover rounded-t-lg border-none'
              alt='A Freelancer'
            />
            <div className='flex flex-col justify-between absolute p-3 w-full h-full top-0 shadow-inner rounded-t-lg border-none'>
              <div className='flex flex-row justify-between'>
                <div className='flex items-center rounded-sm bg-white bg-opacity-40 font-bold text-xs text-white py-2 px-2 h-7'>
                  <img className='inline-block mr-1' src={StatusOnline} />
                  {item?.isOnline ? 'Available' : 'Offline'}
                </div>
                <div className='font-bold text-base text-white py-0.5'>
                  <button>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z'
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className='flex flex-row justify-between items-end'>
                <div>
                  <div className='font-bold text-lg text-white'>
                    {item?.name}
                  </div>
                  <div className='font-regular text-xs text-white'>
                    London, UK (GMT +1)
                  </div>
                </div>
                <div className='font-bold text-base text-white'>
                  ${item?.pricePerHour}/hr
                </div>
              </div>
            </div>
          </div>
          <div className='mt-3 px-3 pb-3'>
            <div className='flex flex-row items-center'>
              <div className='flex flex-row items-center'>
                <img src={RatingIcon} alt='Rating' />
                <span className='font-roboto font-bold text-xs mx-1 mt-1 text-rating'>
                  {item?.rating ? item?.rating : 'N/A'}
                </span>
                <span className='font-roboto font-regular text-xs text-primary mx-0.5 mt-1'>
                  ({item?.numberOfReviews})
                </span>
              </div>
              {item?.isPro && (
                <img
                  src={VerifiedProIcon}
                  alt='Verified Pro'
                  className='mx-2'
                />
              )}
            </div>
            <div className='mt-1.5 flex flex-row items-center'>
              <div className='flex flex-row items-center'>
                <img src={NewTalentIcon} alt='New Talent' />
                <span className='font-roboto font-regular text-sm ml-1 mt-1'>
                  New in
                </span>
                <span className='font-roboto font-bold text-sm ml-1.5 mt-1'>
                  UX Design
                </span>
              </div>
            </div>
            <div className='mt-2'>
              <h2 className='font-roboto font-bold text-sm max-h-9 overflow-hidden overflow-ellipsis line-clamp-2'>
                {item?.title}
              </h2>
            </div>
            <div className='mt-1'>
              <div className='space-y-1'>
                {item?.skills.slice(0, 3).map((skill, index) => (
                  <div
                    className='inline-block py-1.5 px-2 mr-1 bg-chip rounded-full font-regular text-xs'
                    key={index}
                  >
                    {skill?.isVerified && (
                      <img
                        className='mr-1.5 float-left'
                        src={SkillVerifiedIcon}
                        alt='Skill verified'
                      />
                    )}
                    {skill.name}
                  </div>
                ))}
              </div>
            </div>
            {item?.isOnline && (
              <div className='mt-1.5'>
                <div className='flex flex-row items-center'>
                  <img src={InstantChatIcon} alt='Instant Chat' />
                  <span className='font-roboto font-medium text-xs ml-1 mt-1'>
                    {item?.name}
                  </span>
                  <span className='font-roboto font-regular text-xs ml-1.5 mt-1'>
                    is ready to chat
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

FreelancerCard.prototype = {
  item: PropTypes.string.isRequired,
};

export default FreelancerCard;
