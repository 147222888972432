import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
// import Image from './image';
// import {} from '../../assets/images/amazon.png'

export default function TrustedBodies() {
  return (
    <div className='py-10 px-2 lg:p-20 bg-white'>
      <div className='container'>
        <div className='flex flex-row justify-center xl:justify-between flex-wrap items-center'>
          <div className='flex-basis xl:flex-basis-0 text-center xl:text-left mb-4 xl:mb-0 '>
            <p className='text-base font-bold w-52 m-auto'>
              Trusted by over 3,000 companies around the world
            </p>
          </div>
          <div className='mb-4 xl:mb-0 mr-4 xl:mr-0'>
            <StaticImage src='../../assets/images/amazon.png' />
          </div>
          <div className='mb-4 xl:mb-0 mr-4 xl:mr-0'>
            <StaticImage src='../../assets/images/khan-academy.png' />
          </div>
          <div className='mb-4 xl:mb-0 mr-4 xl:mr-0'>
            <StaticImage src='../../assets/images/wamda.png' />
          </div>
          <div className='mb-4 xl:mb-0 mr-4 xl:mr-0'>
            <StaticImage src='../../assets/images/noon.png' />
          </div>
          <div className='mb-4 xl:mb-0 mr-4 xl:mr-0'>
            <StaticImage src='../../assets/images/floranow.png' />
          </div>
        </div>
      </div>
    </div>
  );
}
