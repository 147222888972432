import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

export default function Navbar() {
  const [isDropDown, setDropdown] = React.useState(false);
  return (
    <div className='container'>
      <nav>
        <div className='flex flex-row justify-between py-6 px-5 md:px-10'>
          <div className=''>
            <Link to='/'>
              <StaticImage
                src='../../assets/images/header-logo.png'
                alt='Logo'
              />
            </Link>
          </div>
          <div className='space-x-3 flex items-center'>
            <div className='inline-block pb-1 mr-4'>
              <div>
                <Link
                  className='rounded font-bold text-base text-white py-2.5 px-3 bg-nav-btn'
                  to='/'
                >
                  I’m a freelancer
                </Link>
              </div>
            </div>
            <div className='relative inline-block text-left  mr-4'>
              {/* <div> */}
              <button
                type='button'
                className='inline-flex justify-center items-end w-full font-bold text-base text-white focus:outline-none'
                id='options-menu'
                aria-expanded='true'
                aria-haspopup='true'
                onClick={() => setDropdown(!isDropDown)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9'
                  />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>
              {/* </div> */}

              <div
                className={`${
                  isDropDown ? 'visible' : 'hidden'
                } origin-top-right absolute right-0 mt-2 w-72 z-999 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`}
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='options-menu'
              >
                <div className='py-1' role='none'>
                  <strong className='py-1 px-4 lang_title text-black text-base font-medium ltr:pl-4 rtl:pr-4 pt-4 block mb-1 ng-tns-c60-4'>
                    Language
                  </strong>
                  <a
                    href='#'
                    className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                    role='menuitem'
                  >
                    English
                  </a>
                  <a
                    href='#'
                    className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                    role='menuitem'
                  >
                    العربية
                  </a>
                </div>
              </div>
            </div>
            <div className='inline-block pb-1'>
              <Link to='/' className='font-bold text-white text-base'>
                Hello, Sign in
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
