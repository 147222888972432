/* eslint-disable react/prop-types */
import React from 'react';
// import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../sections/homePage/hero';
import FreelancersSection from '../sections/homePage/freelancersSection';
import ActiveFreelancers from '../sections/homePage/activeFreelancers';
import TrustedBodies from '../sections/common/trustedBodies';
import Community from '../sections/common/community';
import OfferService from '../components/homePage/offerService';
import SEO from '../components/seo';

function IndexPage({ data }) {
  console.log('Data******', data);
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title='Home'
      />
      <Hero />
      <TrustedBodies />
      <FreelancersSection freelancers={data?.ureed?.freelancers} />
      <ActiveFreelancers freelancers={data?.ureed?.freelancers} />
      <Community />
      <OfferService />
    </Layout>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    ureed {
      freelancers {
        id
        name
        title
        bio
        photoUrl
        photoUrlThumb
        coverUrl
        cloudinaryLinkAvatar
        cloudinaryLinkCoverPhoto
        isPro
        status
        isOnline
        languages {
          id
          name
          nameAr
          nameEn
        }
        country {
          name
        }
        city {
          id
          name
        }
        pricePerHour
        weeklyLimit
        rating
        numberOfReviews
        totalEarnings
        tagLine
        skills {
          name
          nameAr
          isVerified
          id
          nameEn
        }
      }
    }
  }
`;
