import React from 'react';
import { Link } from 'gatsby';
import BgImage from '../../assets/images/offer-service-bg.png';
export default function OfferService() {
  return (
    <div className='offerService bg-white xl:py-16'>
      <div className='container shadow rounded-lg bg-dark'>
        <div
          className='offerService__inner bg-right-bottom103 bg-no-repeat bg-right-bottom'
          style={{ backgroundImage: `url(${BgImage})` }}
        >
          <div className='offerService__inner__content py-20 px-10 lg:p-20'>
            <h4 className='font-playfair text-3xl font-bold text-white'>
              Looking to offer your services?
            </h4>
            <p className='mt-5 sm:mt-8 sm:flex font-roboto text-xl font-regular text-white'>
              We are constantly on the lookout for great talents to join us
              build the largest vetted network of freelancers in the region
              There’s no better place than Ureed.com to kickstart your freelance
              journey.
            </p>
            <div className='mt-5 sm:mt-8 sm:flex justify-start'>
              <div className='rounded-md shadow'>
                <Link
                  to='/'
                  className='w-full lg:flex items-center justify-center px-8 py-2 bg-tuftsBlue border border-transparent text-base font-roboto font-bold rounded-md text-white md:py-3 md:text-lg md:px-10'
                >
                  Create profile
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
