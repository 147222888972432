/* eslint-disable react/prop-types */
import React from 'react';
import BlogPost from '../../components/common/blogPost';
import Blog1 from '../../assets/images/blog-post-1.png';
import Blog2 from '../../assets/images/blog-post-2.png';
import Blog3 from '../../assets/images/blog-post-3.png';
import Blog4 from '../../assets/images/blog-post-4.png';

export default function CommunitySection() {
  return (
    <div className='fs-section py-20 px-10 lg:p-20 bg-white'>
      <div className='container'>
        <div className='flex flex-col lg:flex-row justify-between'>
          <section className='fs-header'>
            <h1 className='font-playfair text-3xl font-bold'>
              Latest about our community
            </h1>
          </section>
        </div>
        <div className='mt-12 pb-12 flex xl:grid xl:grid-flow-col xl:grid-cols-2 grid-cols-1 grid-rows-1 xl:grid-rows-2 xl:gap-x-16 xl:gap-y-12 overflow-x-auto'>
          {list.map((item, index) => (
            <BlogPost item={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

const list = [Blog1, Blog2, Blog3, Blog4];
